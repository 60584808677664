// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-js": () => import("./../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-menu-js": () => import("./../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-beverages-js": () => import("./../src/pages/menu/beverages.js" /* webpackChunkName: "component---src-pages-menu-beverages-js" */),
  "component---src-pages-menu-burgers-js": () => import("./../src/pages/menu/burgers.js" /* webpackChunkName: "component---src-pages-menu-burgers-js" */),
  "component---src-pages-menu-sides-and-snacks-js": () => import("./../src/pages/menu/sides-and-snacks.js" /* webpackChunkName: "component---src-pages-menu-sides-and-snacks-js" */),
  "component---src-pages-our-story-js": () => import("./../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-shops-js": () => import("./../src/pages/shops.js" /* webpackChunkName: "component---src-pages-shops-js" */)
}

